<template>
  <div class="mb-5">
    <Breadcrumbs main="" title="Hosts and IPs" />
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-12">
                <div slot="with-padding">
                    <h4 class="f-w-900">Whitelisting</h4>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <div slot="with-padding">
                    <p class="f-16">If your monitoring service is functioning correctly, no action is required on your part. However, in the event that you encounter any false positive results, it is possible that your hosting provider has implemented blocking measures for the hosts and IPs used by Pingmonk. To ensure the uninterrupted operation of your monitoring service, we recommend whitelisting the necessary hosts and IPs. By doing so, you can ensure that any requests sent by Pingmonk are not inadvertently blocked by your hosting provider's security measures.
                    </p>
                </div>
            </div>
        </div>
      <div class="row">
        <div class="col-md-12">
          <div slot="with-padding">
            <h4 class="f-w-900">
              Here is the list of our Host and Ips so you can whitelist them if needed.
            </h4>
          </div>
        </div>
      </div><hr>
        <div class="row">
        <div class="col-md-12 f-22 f-w-900 text-info">
            <ul v-for="ip in ip_list" :key="ip">
                <li>{{ ip.host }}</li><hr>
            </ul>
        </div>
      </div>

      <div class="row col-md-12">
            <h4 class="f-w-900">
              You can download the list of our Host and Ips in CSV format.
            </h4>
      </div>
      <div class="row col-md-12">
        <span class="link_txt" @click="downloadCsv()">
            Click here to download a list of hosts and IP addresses in CSV format.
        </span>
      </div>
    </div>
  </div>
</template>



<script>

import IpController from '../../../services/ipController'
import Config from '../../../services/config'
export default{
    name: 'LocationsAndIps',
    data(){
        return{
            ip_list:[]
        }
    },
    mounted(){
        this.getIps()
    },
    methods:{
        async getIps(){
            let response = await IpController.getIps()
            this.ip_list = response.data
        },
        async downloadCsv(){
            let uri =  Config.exportHostnIp
            var link = document.createElement("a");
            link.href = uri;
            link.setAttribute('download', 'host_ip_list.csv');
            document.body.appendChild(link);
            console.log(link)
            link.click();
            link.remove();
            this.$toasted.show('Downloaded Host and IPs list.', {theme: 'outline',position: "bottom-center", type: 'success', duration: 2000});
        }
    }

}

</script>