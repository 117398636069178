let host = process.env.VUE_APP_BACKHOST
// let host = 'fraudavoid.makeapk.com'
let port =  process.env.VUE_APP_BACKPORT
let baseUrl = process.env.VUE_APP_BASEURL
module.exports = {
  host : host,
  port : port,
  hertbeatUrl  : baseUrl+'/hb/',
  hblogs : baseUrl+'/hblogs/',
  exportMonitor : baseUrl+'/monitor/exports',
  telegramBot : process.env.VUE_APP_TELEGRAM,
  skypebot:process.env.VUE_APP_SKYPE_ID,
  webPushUrl : process.env.VUE_APP_WEBPUSH_URL,
  slacVerifyurl:process.env.VUE_APP_SLACK_VERIFY_URL,
  statusPageUrl: process.env.VUE_APP_STATUS_API+'?statsid=',
  googleLogin:baseUrl+'/login/google',
  refUrl : process.env.VUE_APP_REGISTER_URL,
  exportHostnIp : baseUrl+'/deamonwh/csv',
}